<template>
  <v-row align="center" justify="center">
    <v-col cols="12" md="8" class="pa-0">
      <v-card
        class="mx-xs-0 mx-sm-auto px-xs-0 py-4 px-md-4"
        max-width="600"
        outlined
      >
        <CardToolbar
          :icon="UPLOAD_TO_DATABASE_ICON"
          title="Load Data"
        />

        <v-card-text class="mt-n4">
          <p :class="`${DEFAULT_TEXT}`">Modify data in the database by uploading a CSV file.</p>

          <v-expansion-panels>
            <v-expansion-panel
              v-for="(type, i) in uploadTypes"
              :key="i"
            >
              <v-expansion-panel-header class="primary--text">{{ type.name }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <p :class="`${DEFAULT_TEXT}`">{{ type.description }}</p>
                <v-row>
                  <v-col v-for="button in type.buttons" :key="button.type" sm="6" xs="12">
                    <v-btn
                      @click="push(`load-data/${type.action}/${button.type}`)"
                      block
                      outlined
                      :color="button.color"
                    >
                      {{ button.text }}
                      <v-icon right>{{ button.icon }}</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { ACTIONS, ICONS, TEXT_COLOR, COLOR, } from '../constants'
import { push } from '../router'
import { TYPE as AGENT_TYPE } from '../models/dto/Agent'
import { TYPE as MORTGAGE_DEAL_TYPE } from '../models/dto/MortgageDeal'

export default {
  name: 'LoadData',
  components: {
    CardToolbar: () => import('../components/layout/toolbars/CardToolbar.vue'),
  },
  data: () => ({
    uploadTypes: {
      new: {
        action: ACTIONS.NEW,
        name: 'New Data',
        description: 'Insert new data into the database.',
        buttons: [
          {
            color: COLOR.PRIMARY,
            icon: ICONS.AGENT,
            text: 'Agents',
            type: AGENT_TYPE.AGENT,
          },
          {
            color: COLOR.PRIMARY,
            icon: ICONS.MORTGAGE_DEAL,
            text: 'Mortgage Deals',
            type: MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL,
          }
        ]
      },
      update: {
        action: ACTIONS.UPDATE,
        name: 'Update Data',
        description: 'Update specific kinds of data in the database.',
        buttons: [
          {
            color: COLOR.PRIMARY,
            icon: ICONS.AGENT,
            text: 'Agents',
            type: AGENT_TYPE.AGENT,
          },
          {
            action: ACTIONS.UPDATE,
            color: COLOR.PRIMARY,
            icon: ICONS.MORTGAGE_DEAL,
            text: 'Mortgage Deals',
            type: MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL,
          }
        ],
      }
    }
  }),
  methods: {
    initTemplateConstants() {
      this.DEFAULT_TEXT = TEXT_COLOR.DEFAULT
      this.UPLOAD_TO_DATABASE_ICON = ICONS.UPLOAD_TO_DATABASE
    },
  },
  created () {
    this.initTemplateConstants()
    this.push = push
  }
}
</script>